<template>
    <div style="height: 100%">
      <!--      头部      -->
      <div class="iq-top-navbar">
        <el-page-header @back="goBack" content="修改密码"> </el-page-header>
      </div>
      <!--    内容    -->
      <div class="center-content">
        <div class="iq-card-body">
          <div style="min-height: 60vh; width: 40%">
            <el-form
              ref="resetform"
              :model="userInfo"
              label-width="100px"
              :rules="resetFrom"
            >
              <el-form-item label="登录账号">
                <el-input v-model="userInfo.phone" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="旧密码" prop="oldPwd">
                <el-input
                  v-model.trim="userInfo.oldPwd"
                  show-password
                  :disabled="disabled"
                ></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPwd">
                <el-input
                  v-model.trim="userInfo.newPwd"
                  show-password
                  title="6-16个字符"
                  :disabled="disabled"
                ></el-input>
                <el-tooltip
                  placement="right"
                  style="position: absolute; right: -30px"
                >
                  <div slot="content">密码为6~16个英文字母、数字、符号组成！</div>
                  <el-button type="text"
                    ><i class="el-icon-question" style="font-size: 20px"></i
                  ></el-button>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="确认新密码" prop="newPwdV">
                <el-input
                  v-model.trim="userInfo.newPwdV"
                  show-password
                  :disabled="disabled"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="openEdit"
                  v-show="showBtn"
                  plain
                  >{{ btnText }}</el-button
                >
                <el-button
                  type="primary"
                  @click="onSubmit('resetform')"
                  v-show="showSaveBtn"
                  >确认修改</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { User } from "../../../components/HospitalDomain/User";
  import bread from "../../../components/common/breadcrumb";
  export default {
    name: "ResetPwd",
    components: {
      bread,
    },
    data() {
      var user = new User(this.TokenClient, this.Services.Authorization);
      return {
        UserDomain: user,
        userInfo: "",
        disabled: true,
        showSaveBtn: false,
        showBtn: true,
        btnText: "修改密码",
        resetFrom: {
          oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
          newPwd: [
            { required: true, message: "请输入新密码", trigger: "blur" },
            {
              pattern: /^(?![\s\u4e00-\u9fa5]+$).{6,16}$/,
              message: "密码为6~16个英文字母、数字、符号组成！",
              trigger: "blur",
            },
          ],
          newPwdV: [
            { required: true, message: "请再次输入密码", trigger: "blur" },
          ],
        },
      };
    },
    created() {
      this.getUserInfo();
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getUserInfo() {
        this.userInfo = this.UserDomain.GetSessionUserInfo();
      },
      openEdit() {
        if (this.btnText == "取消") {
          this.btnText = "修改密码";
          this.disabled = true;
          this.showSaveBtn = false;
          this.$refs["resetform"].resetFields();
        } else {
          this.btnText = "取消";
          this.disabled = false;
          this.showSaveBtn = true;
        }
      },
      onSubmit(formName) {
        var _this = this;
        _this.$refs[formName].validate((valid) => {
          if (valid) {
            if (_this.userInfo.newPwd == _this.userInfo.newPwdV) {
              _this.UserDomain.ChangePassword(
                _this.userInfo.oldPwd,
                _this.userInfo.newPwd,
                function () {
                  _this
                    .$confirm("修改成功，立即重新登录?", "提示", {
                      confirmButtonText: "确定",
                      type: "warning",
                      showClose: false,
                      showCancelButton: false,
                      closeOnClickModal: false,
                      closeOnPressEscape: false,
                    })
                    .then(() => {
                      // this.getRequest("/logout");
                      window.sessionStorage.clear();
                      _this.$message.success("已退出");
                      _this.$router.push("/login");
                      //触发后禁止浏览器的后退键
                      history.pushState(null, null, document.URL);
                      window.addEventListener(
                        "popstate",
                        function (e) {
                          history.pushState(null, null, document.URL);
                        },
                        false
                      );
                    });
                },
                function () {
                  _this.$message.error("旧密码输入错误，请重新输入");
                  _this.$refs[formName].resetFields();
                }
              );
            } else {
              this.$message.error("两次密码输入不一致哦");
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .el-page-header {
    line-height: 73px;
    padding-left: 20px;
  }
  
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  /*content*/
  </style>
  
  